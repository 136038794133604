<template>
	<w-list>
		<w-divider />
		<template v-for="service in value">
			<w-list-tile :key="service.id" avatar @click="selectable ? selectTarget(service) : undefined">
				<w-list-tile-avatar :color="service.category.color">
					<span class="white--text">{{ service.category.abbreviation }}</span>
				</w-list-tile-avatar>
				<w-list-tile-content>
					<w-list-tile-title v-text="service.name" />
					<w-list-tile-sub-title v-text="'Référence : ' + service.unique_reference" />
				</w-list-tile-content>
			</w-list-tile>
			<w-divider :key="`${service.id}-divider`" />
		</template>
	</w-list>
</template>

<script>
export default {
	name: 'CatalogOfferServicesList',
	props: {
		selectable: {
			default: true,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Array
		},
		selectTarget: {
			required: false,
			default: () => {
				return () => {}
			},
			type: Function
		}
	},
	mounted () {},
	methods: {}
}
</script>
